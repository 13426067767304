import {Typography, Link, List, ListItem, Box, ListItemText} from '@mui/material';

function PressPlayBibleHome(props) {
    return (
        <Box sx={{padding: 3, fontFamily: 'Georgia, serif'}}>
            <Box className="page" sx={{marginBottom: 4}}>

                <Box className="translations-content-container">
                    <Box className="container">
                        <Box className="header" sx={{marginBottom: 2}}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Privacy Policy for the Press Play Bible
                            </Typography>
                        </Box>
                        <Box className="tab-content translations-content-item en visible" id="en">
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                Last updated: September 07, 2024
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                This Privacy Policy describes our policies and procedures on the collection, use, and
                                disclosure of your information when you use the Service. It explains your privacy rights
                                and how the law protects you.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                We use your personal data to provide and improve the Service. By using the Service, you
                                agree to the collection and use of information in accordance with this Privacy Policy.
                                This Privacy Policy was created with the help of the{' '}
                                <Link
                                    href="https://www.termsfeed.com/privacy-policy-generator/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy Generator
                                </Link>.
                            </Typography>
                            <Typography variant="h5" component="h2" gutterBottom sx={{marginBottom: 2}}>
                                Interpretation and Definitions
                            </Typography>
                            <Typography variant="h6" component="h5" gutterBottom sx={{marginBottom: 1}}>
                                Interpretation
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                The words with initial capital letters have meanings defined under the following
                                conditions.
                                These definitions will have the same meaning regardless of whether they appear in
                                singular
                                or plural form.
                            </Typography>
                            <Typography variant="h6" component="h5" gutterBottom sx={{marginBottom: 1}}>
                                Definitions
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                For the purposes of this Privacy Policy:
                            </Typography>
                            <List sx={{marginBottom: 3}}>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Account</strong> means a unique account created for you to access our
                                        Service or parts of our Service.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Affiliate</strong> means an entity that controls, is controlled by, or
                                        is
                                        under common control with a party, where "control" means ownership of 50% or
                                        more
                                        of the shares, equity interest, or other securities entitled to vote for the
                                        election of directors or other managing authority.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Application</strong> refers to Press Play Bible, the software program
                                        provided by the Company.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Company</strong> (referred to as either "the Company", "We", "Us" or
                                        "Our"
                                        in this Agreement) refers to Press Play Bible.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Country</strong> refers to: China
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Device</strong> means any device that can access the Service, such as a
                                        computer, cellphone, or digital tablet.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Personal Data</strong> is any information that relates to an identified
                                        or identifiable individual.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Service</strong> refers to the Application.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Service Provider</strong> means any natural or legal person who
                                        processes
                                        the data on behalf of the Company. It refers to third-party companies or
                                        individuals
                                        employed by the Company to facilitate the Service, provide the Service on behalf
                                        of
                                        the Company, perform services related to the Service, or assist the Company in
                                        analyzing how the Service is used.
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>Usage Data</strong> refers to data collected automatically, either
                                        generated
                                        by the use of the Service or from the Service infrastructure itself (for
                                        example,
                                        the duration of a page visit).
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        <strong>You</strong> means the individual accessing or using the Service, or the
                                        company or other legal entity on behalf of which such individual is accessing or
                                        using the Service, as applicable.
                                    </Typography>
                                </ListItem>
                            </List>
                            <Typography variant="h5" component="h2" gutterBottom sx={{marginBottom: 2}}>
                                Collecting and Using Your Personal Data
                            </Typography>
                            <Typography variant="h6" component="h3" gutterBottom sx={{marginBottom: 1}}>
                                Types of Data Collected
                            </Typography>
                            <Typography variant="h6" component="h4" gutterBottom sx={{marginBottom: 1}}>
                                Personal Data
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                While using our Service, we may ask you to provide certain personally identifiable
                                information that can be used to contact or identify you. Personally identifiable
                                information may include, but is not limited to:
                            </Typography>
                            <List sx={{marginBottom: 3}}>
                                <ListItem>
                                    <ListItemText primary="Usage Data"/>
                                </ListItem>
                            </List>
                            <Typography variant="h6" component="h4" gutterBottom sx={{marginBottom: 1}}>
                                Usage Data
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                Usage Data is collected automatically when using the Service.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                Usage Data may include information such as your device's Internet Protocol address
                                (e.g.,
                                IP address), browser type, browser version, the pages of our Service that you visit, the
                                time and date of your visit, the time spent on those pages, unique device identifiers,
                                and other diagnostic data.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                When you access the Service by or through a mobile device, we may collect certain
                                information automatically, including, but not limited to, the type of mobile device you
                                use, your mobile device unique ID, the IP address of your mobile device, your mobile
                                operating system, the type of mobile Internet browser you use, unique device
                                identifiers,
                                and other diagnostic data.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                We may also collect information that your browser sends whenever you visit our Service
                                or when you access the Service by or through a mobile device.
                            </Typography>
                            <Typography variant="h6" component="h3" gutterBottom sx={{marginBottom: 1}}>
                                Use of Your Personal Data
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                The Company may use Personal Data for the following purposes:
                            </Typography>
                            <List sx={{marginBottom: 3}}>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>To provide and maintain our Service</strong>}
                                        secondary="Including to monitor the usage of our Service."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>To manage Your Account</strong>}
                                        secondary="To manage your registration as a user of the Service. The Personal Data you provide can give you access to different functionalities of the Service available to you as a registered user."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>For the performance of a contract</strong>}
                                        secondary="The development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased or any other contract with us through the Service."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>To contact you</strong>}
                                        secondary="To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including security updates, when necessary or reasonable for their implementation."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>To provide you with news</strong>}
                                        secondary="With news, special offers, and general information about other goods, services, and events we offer that are similar to those you have already purchased or enquired about unless you have opted not to receive such information."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>To manage your requests</strong>}
                                        secondary="To attend and manage your requests to us."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>For business transfers</strong>}
                                        secondary="We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by us about our Service users is among the assets transferred."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>For other purposes</strong>}
                                        secondary="We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Service, products, services, marketing, and your experience."
                                    />
                                </ListItem>
                            </List>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                We may share your personal information in the following situations:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>With Service Providers:</strong>}
                                        secondary="We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>For business transfers:</strong>}
                                        secondary="We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>With Affiliates:</strong>}
                                        secondary="We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>With business partners:</strong>}
                                        secondary="We may share Your information with Our business partners to offer You certain products, services or promotions."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>With other users:</strong>}
                                        secondary="When You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside."
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<strong>With Your consent:</strong>}
                                        secondary="We may disclose Your personal information for any other purpose with Your consent."
                                    />
                                </ListItem>
                            </List>
                            <Typography variant="h6" component="h3" gutterBottom sx={{marginBottom: 1}}>
                                Retention of Your Personal Data
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                The Company will retain your Personal Data only for as long as is necessary for the
                                purposes set out in this Privacy Policy. We will retain and use your Personal Data to
                                the extent necessary to comply with our legal obligations (for example, if we are
                                required to retain your data to comply with applicable laws), resolve disputes, and
                                enforce our legal agreements and policies.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                The Company will also retain Usage Data for internal analysis purposes. Usage Data is
                                generally retained for a shorter period of time, except when this data is used to
                                strengthen the security or improve the functionality of our Service, or we are
                                legally obligated to retain this data for longer periods.
                            </Typography>
                            <Typography variant="h6" component="h3" gutterBottom sx={{marginBottom: 1}}>
                                Transfer of Your Personal Data
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                Your information, including Personal Data, is processed at the Company's operating
                                offices and in any other locations where the parties involved in the processing are
                                located. This means that your information may be transferred to, and maintained on,
                                computers located outside of your state, province, country, or other governmental
                                jurisdiction where the data protection laws may differ from those of your jurisdiction.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                Your consent to this Privacy Policy followed by your submission of such information
                                represents your agreement to that transfer.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                The Company will take all reasonable steps to ensure that your data is treated securely
                                and in accordance with this Privacy Policy. No transfer of your Personal Data will take
                                place to an organization or country unless there are adequate controls in place,
                                including the security of your data and other personal information.
                            </Typography>
                            <Typography variant="h6" component="h3" gutterBottom sx={{marginBottom: 1}}>
                                Delete Your Personal Data
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                You have the right to delete or request that we assist in deleting the Personal Data
                                that we have collected about you.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                Our Service may provide you with the ability to delete certain information about you
                                from within the Service.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                You may update, amend, or delete your information at any time by signing in to your
                                account, if you have one, and visiting the account settings section that allows you to
                                manage your personal information. You may also contact us to request access to, correct,
                                or delete any personal information that you have provided to us.
                            </Typography>
                            <Typography variant="body1" paragraph sx={{marginBottom: 3}}>
                                Please note, however, that we may need to retain certain information when we have a
                                legal obligation or lawful basis to do so.
                            </Typography>


                            <Typography variant="h5" gutterBottom>
                                Disclosure of Your Personal Data
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Business Transactions
                            </Typography>
                            <Typography variant="body1" paragraph>
                                In the event that the Company undergoes a merger, acquisition, or sale of assets, your
                                Personal
                                Data may be transferred. We will notify you prior to any such transfer, and your data
                                will then
                                be subject to a different Privacy Policy.
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Law Enforcement
                            </Typography>
                            <Typography variant="body1" paragraph>
                                The Company may be required to disclose your Personal Data if mandated by law or in
                                response to
                                valid requests from public authorities, such as a court or government agency.
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Other Legal Requirements
                            </Typography>
                            <Typography variant="body1" paragraph>
                                The Company may disclose your Personal Data if we believe, in good faith, that such
                                action is
                                necessary to:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Comply with a legal obligation"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Protect and defend the rights or property of the Company"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Prevent or investigate possible wrongdoing in connection with the Service"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Protect the personal safety of users of the Service or the public"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Protect against legal liability"/>
                                </ListItem>
                            </List>

                            <Typography variant="h5" gutterBottom>
                                Security of Your Personal Data
                            </Typography>
                            <Typography variant="body1" paragraph>
                                While the security of your Personal Data is important to us, no method of transmission
                                over the
                                Internet or electronic storage is entirely secure. We strive to use commercially
                                acceptable means
                                to protect your data, but we cannot guarantee its absolute security.
                            </Typography>

                            <Typography variant="h5" gutterBottom>
                                Children's Privacy
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Our Service is not intended for anyone under the age of 13. We do not knowingly collect
                                personally identifiable information from children under 13. If you are a parent or
                                guardian and
                                you become aware that your child has provided us with Personal Data, please contact us.
                                If we
                                learn that we have collected Personal Data from anyone under 13 without parental
                                consent, we
                                will take steps to delete such information from our servers.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                If we need to rely on consent as the legal basis for processing your information and
                                your country
                                requires parental consent, we may seek consent from your parent before collecting and
                                using that
                                information.
                            </Typography>

                            <Typography variant="h5" gutterBottom>
                                Links to Other Websites
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Our Service may contain links to other websites that are not operated by us. If you
                                click on a
                                third-party link, you will be directed to that third party's site. We strongly advise
                                you to review
                                the Privacy Policy of every site you visit.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                We have no control over and assume no responsibility for the content, privacy policies,
                                or practices
                                of any third-party sites or services.
                            </Typography>

                            <Typography variant="h5" gutterBottom>
                                Changes to This Privacy Policy
                            </Typography>
                            <Typography variant="body1" paragraph>
                                We may update our Privacy Policy from time to time. We will notify you of any changes by
                                posting
                                the new Privacy Policy on this page.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                You will be informed via email and/or through a prominent notice on our Service before
                                any change
                                becomes effective. The "Last updated" date at the top of this Privacy Policy will also
                                be updated.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                We encourage you to review this Privacy Policy periodically for any changes. Changes to
                                this
                                Privacy Policy are effective when they are posted on this page.
                            </Typography>

                            <Typography variant="h5" gutterBottom>
                                Contact Us
                            </Typography>
                            <Typography variant="body1" paragraph>
                                If you have any questions about this Privacy Policy, please contact us:
                            </Typography>
                            <List>
                                <ListItem>
                                    <Typography variant="body1">
                                        By email:{' '}
                                        <Link href="mailto:amossitenda@gmail.com" color="inherit">
                                            amossitenda@gmail.com
                                        </Link>
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography variant="body1">
                                        By visiting this page on our website:{' '}
                                        <Link href="https://amossitenda.com" rel="external nofollow noopener"
                                              target="_blank" color="inherit">
                                            https://amossitenda.com
                                        </Link>
                                    </Typography>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Box sx={{backgroundColor: '#f5f5f5', padding: 2, textAlign: 'center'}}>
                        <Typography variant="body2">
                            Generated using{' '}
                            <Link
                                href="https://www.termsfeed.com/privacy-policy-generator/"
                                target="_blank"
                                rel="noopener noreferrer"
                                color="inherit"
                            >
                                TermsFeed Privacy Policy Generator
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default PressPlayBibleHome;