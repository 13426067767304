/**
 * Page providing a centralized naming convention for frontend routes
 */

const publicBaseUrl = "";
const clientBaseUrl     = "/client"

export const publicPaths = {
    landingPagePath                : `${publicBaseUrl}/`,
    pressPlayBiblePath             : `${publicBaseUrl}/press-play-bible`,
    pressPlaySongbookPath          : `${publicBaseUrl}/press-play-songbook`,
}

export const clientPaths = {
    homePath                                : `${clientBaseUrl}`,
}
